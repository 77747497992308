<template>
  <div class="bg-white p-20 rounded">
    <div class="mb-20">
      <a-radio-group v-model="status" @change="doGetPartnerList">
        <a-radio-button :value="1">我的星球伙伴</a-radio-button>
        <a-radio-button :value="0">待通过</a-radio-button>
        <a-radio-button :value="-1">已拒绝</a-radio-button>
      </a-radio-group>
    </div>
    <div class="mb-20">
      <a-row :gutter="20" v-if="list.length">
        <a-col :span="12" v-for="(item, index) in list" :key="item.CompanyID">
          <div :class="{ 'mt-20': index > 1 }" class="relative">
            <a-space
              v-if="item.Status === 0"
              class="text-sm text-white font-medium absolute right-10 z-10"
              style="top: 164px"
            >
              <div
                class="cursor-pointer"
                @click="doConfirmPartner(item.CompanyID, 1)"
              >
                通过
              </div>
              <div
                class="cursor-pointer"
                @click="doConfirmPartner(item.CompanyID, -1)"
              >
                不通过
              </div>
            </a-space>

            <div
              v-if="item.Status === -1"
              @click="doJoinPartner(item)"
              class="
                text-sm text-white
                font-medium
                absolute
                right-10
                cursor-pointer
                z-10
              "
              style="top: 164px"
            >
              成为伙伴
            </div>
            <div
              @click="doDelPartner(item)"
              v-if="item.Status === 1"
              class="
                text-sm text-white
                font-medium
                absolute
                top-10
                right-10
                cursor-pointer
                z-10
              "
            >
              移除
            </div>
            <partner-card :dataSource="item.company"></partner-card>
          </div>
        </a-col>
      </a-row>
      <a-empty v-else></a-empty>
    </div>
    <div class="text-center" v-if="list.length">
      <a-pagination
        :pageSize="limit"
        :total="total"
        v-model="current"
        @change="doGetPartnerList"
      ></a-pagination>
    </div>
  </div>
</template>

<script>
import { upload } from "@/api";
import {
  delPartner,
  getPartnerList,
  joinPartner,
  confirmPartner,
} from "@/api/partner.js";
export default {
  data() {
    return {
      status: 1,
      list: [],
      limit: 4,
      total: 0,
      current: 1,
    };
  },
  inject: ["options"],
  computed: {
    company() {
      return this.$store.state.user.USER_INFO.company_id;
    },
  },
  created() {
    this.doGetPartnerList();
  },
  methods: {
    doDelPartner(item) {
      const del = this.delPartner,
        id = item.CompanyID,
        name = item.company.name;
      this.$confirm({
        title: "星球伙伴移除",
        content: `您将把《${name}》从您的星球伙伴中移出，是否移除`,
        okText: "移除",
        cancelText: "取消",
        okType: "danger",
        onOk: () => {
          del(id);
        },
      });
    },
    doGetPartnerList() {
      const temp = {
        limit: this.limit,
        offset: (this.current - 1) * this.limit,
        status: this.status,
      };
      this.getPartnerList(this.company, temp);
    },
    doConfirmPartner(id, status) {
      this.confirmPartner(id, { status });
    },
    doJoinPartner(item) {
      const join = this.joinPartner,
        id = item.CompanyID,
        name = item.company.name;
      this.$confirm({
        title: "星球伙伴邀请",
        content: `您将邀请《${name}》成为您的星球伙伴，邀请一经发出不可撤回，对方同意后即与您成为星球伙伴啦`,
        okText: "邀请",
        cancelText: "取消",
        okType: "primary",
        onOk: () => {
          join(id);
        },
      });
    },
    transform(item) {
      const industry = this.options.industry;
      const tag = this.options.tag;
      const temp = {
        productCount: 0,
        requirementCount: 0,
        caseCount: 0,
        tags: [],
        name: "",
        value: "",
      };
      temp.id = item.ID;
      if (item.Detail && item.Detail.Info) {
        const info = item.Detail.Info;
        temp.name = info.Name;
        temp.shortName = info.ShortName;
        if (info.Logo && info.Logo.ID) {
          temp.logo = upload() + "/" + info.Logo.ID;
        }
      }
      if (item.Detail && item.Detail.State) {
        const State = item.Detail.State;
        temp.value = State.Values;
      }
      if (item.Detail && item.Detail.Label) {
        const industries = item.Detail.Label.Industries || [];
        const tags = item.Detail.Label.Tags || [];

        temp.tags = industries
          .map((item) => {
            if (item.ID === 0 && item.Name) {
              return item;
            } else {
              return industry[item.ID - 1];
            }
          })
          .filter((item) => item);

        temp.tags1 = tags
          .map((item) => {
            if (item.ID === 0 && item.Name) {
              return item;
            } else {
              return tag[item.ID - 1];
            }
          })
          .filter((item) => item);
      }
      if (item.Stats) {
        temp.productCount = item.Stats.Products;
        temp.caseCount = item.Stats.Cases;
        temp.requirementCount = item.Stats.Requirements;
        temp.solutionCount = item.Stats.Solutions;
      }
      return temp;
    },
    delPartner(id) {
      delPartner(this.company, id)
        .then(() => {
          this.$message.success("移除伙伴成功");
          this.doGetPartnerList();
        })
        .catch(() => {
          this.$message.error("移除伙伴失败");
        });
    },
    getPartnerList(id, data) {
      getPartnerList(id, data)
        .then(({ data }) => {
          const { count, rows } = data;
          this.total = count;
          const temp = rows || [];
          this.list = temp.map((item) => {
            const tem = { ...item };
            tem.company = this.transform({
              Detail: item.Company,
              ID: item.CompanyID,
            });
            return tem;
          });
        })
        .catch((err) => {
          console.log(err);
          this.$message.error("获取伙伴列表失败");
        });
    },
    joinPartner(id) {
      joinPartner(this.company, id)
        .then(() => {
          this.$message.success("发送申请成功");
          this.doGetPartnerList();
        })
        .catch(() => {
          this.$message.error("发送申请失败");
        });
    },
    confirmPartner(id, data) {
      confirmPartner(this.company, id, data)
        .then(() => {
          if (data.status === 1) {
            this.$message.success("恭喜您与该公司成为星球伙伴");
          } else {
            this.$message.success("您拒绝了该公司伙伴要求");
          }

          this.doGetPartnerList();
        })
        .catch(() => {
          this.$message.error("操作失败");
        });
    },
  },
};
</script>